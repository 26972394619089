import React from 'react';
import './styles.css';

export const Info = ({ imgSrc, copy }) => (
  <div className="info-container">
    <p style={{ whiteSpace: 'pre-line' }}>
      {copy}
    </p>

    <a href='https://www.glassdoor.com/Overview/Working-at-Moore-Advanced-EI_IE1810495.11,25.htm' target="_blank" className="careers-link">
      <div className="indeed-btn">
          SEARCH CAREERS
        <div
          style={{
            width: 0,
            height: 0,
            marginLeft: '10px',
            borderTop: '6px solid transparent',
            borderLeft: '9px solid #e08533',
            borderBottom: '6px solid transparent'}}>
        </div>
      </div>
    </a>

    <a href='http://mooreadvancedapplication.azurewebsites.net/' target="_blank" className="careers-link">
      <div className="apply-btn" style={{ marginTop: '15px' }}>
          APPLY NOW
        <div
          style={{
            width: 0,
            height: 0,
            marginLeft: '10px',
            borderTop: '6px solid transparent',
            borderLeft: '9px solid #e08533',
            borderBottom: '6px solid transparent'}}>
        </div>
      </div>
    </a>

    <img src={imgSrc} className="careers-img" />
  </div>
);
