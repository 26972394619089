import React from "react"
import Layout from "../layouts"
import { CareersHeader } from "../components/careers-page/careers-header"
import { Info } from "../components/careers-page/info"

const CareersPage = ({ data }) => {
  const content = data.allContentfulCareers.edges[0].node

  return (
    <Layout>
      <CareersHeader
        imgSrc={content.headerImage.file.url}
        copy={content.headerCopy}
      />
      <Info
        imgSrc={content.careersMainImage.file.url}
        copy={content.careersMain.careersMain}
      />
    </Layout>
  )
}

export default CareersPage

export const pageQuery = graphql`
  query careersContent {
    allContentfulCareers {
      edges {
        node {
          headerCopy
          careersMain {
            careersMain
          }

          headerImage {
            file {
              url
            }
          }
          careersMainImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
